import {
  INIT,
  UPDATE,
  UPDATE_AUTH_STATUS,
  LOGOUT
} from "../action-types/auth.actionTypes";
import { getRequest } from "../utils/service";
import { processAuthData } from "../utils/helpers";
import { reactLocalStorage } from "reactjs-localstorage";
import { LOCAL_STORAGE_KEYS } from "../../keys";

const { accounts, admin, dashboard } = LOCAL_STORAGE_KEYS;

// Process Init Auth Data Actions
export const initAuthStore = async dispatch => {
  let data = await processAuthData();

  dispatch({
    type: INIT,
    payload: { data }
  });
};

// Process Update Actions
export const updateAuthStore = data => ({
  type: UPDATE,
  payload: data
});

// Process Update Status Actions
export const updateAuthStoreStatus = data => ({
  type: UPDATE_AUTH_STATUS,
  payload: data
});

// Process logiut Actions
export const logout = () => ({
  type: LOGOUT
});

export const getAccounts = async dispatch =>
  getRequest("customer/accounts", true)
    .then(res => {
      reactLocalStorage.setObject(accounts, res.data.accounts);
      initAuthStore(dispatch);
    })
    .catch(err => {
      console.log("An Error Occurred while fetching App Settings", { err });
    });

export const getAdminProfileDashboardData = async () =>
  getRequest("admin/me", true)
    .then(res => {
      let adminData = res.data.admin;
      let dashboardData = res.data.dashboard_data;

      reactLocalStorage.setObject(admin, adminData);
      reactLocalStorage.setObject(dashboard, dashboardData);
    })
    .catch(err => {
      console.log("An Error Occurred while fetching App Settings", { err });
    });
