import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";
import {
  POINT_PURCHASE,
  SETUP_FEE
} from "../modules/action-types/billing.actionTypes";
import { verifyPayment, resetStatus } from "../modules/actions/billing.actions";
import { getSettingsAction } from "../modules/actions/setting.actions";
import { initAuthStore } from "../modules/actions/auth.actions";
import { getSelectedAccount } from "../modules/selectors/auth.selectors";
import { getSettings } from "../modules/selectors/setting.selectors";
import {
  getSuccessStatus,
  getMessage,
  getPayment,
  getLoadingStatus
} from "../modules/selectors/billing.selectors";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from "validator";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import PXLayout from "../components/Layout";
import { LOCAL_STORAGE_KEYS } from "../keys";

const {
  setup_fee,
  point_purchase,
  regular_setup_fee,
  startup_setup_fee
} = LOCAL_STORAGE_KEYS;

class BillingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0.0,
      isPageLoading: true,
      isFormError: false,
      minPoint: 1000,
      points: 1000,
      type: "",
      paymentDesc: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleErrorSubmit = this.handleErrorSubmit.bind(this);
  }

  componentDidMount() {
    this.props.resetStatus(this.props.dispatch);
    this.props.getSettingsAction(this.props.dispatch).then(async () => {
      await initAuthStore(this.props.dispatch);
      let account = this.props.account;
      let plan = account.search_plan;
      var minPoint = 1000;
      // Set the default plan points when page first load
      if (plan) {
        if (account.is_startup) {
          minPoint = plan.startup_min_point;
        } else {
          minPoint = plan.min_point;
        }
      }
      await this.init(minPoint);
      console.log({ account });
      this.setState({ isPageLoading: false });
    });
  }

  async verifyPaymentFromServer(paypalOrderId, amountPaid) {
    let payload = {
      paypal_order_id: paypalOrderId,
      amount_paid: amountPaid,
      account_id: this.props.account.id,
      points: this.state.points
    };
    await this.props.verifyPayment(
      this.props.dispatch,
      payload,
      this.state.type
    );
  }

  async init(point) {
    console.log("PAYPAL: ");

    const { type } = this.props.match.params;

    switch (type) {
      case point_purchase:
        this.processPoints(point);
        break;
      case setup_fee:
        this.processSetUpCharges();
        break;

      default:
        break;
    }
  }

  // Get Client ID
  getClientId = () => process.env.REACT_APP_PAYPAL_CLIENT_ID;

  // Process Set fee Charges calculations
  processSetUpCharges() {
    const oneYearInMilliSeconds = 31536000000;

    let account = this.props.account;
    let settings = this.props.settings;
    let settingsLength = settings.length;

    let createdDate = new Date(account.created_at);
    let todayDate = new Date();
    let toDayDateTime = todayDate.getTime();

    // Add one year to account creation date
    let oneYearLater = createdDate.getTime() + oneYearInMilliSeconds;

    // Check of one year later is less than today's date time
    // check if account is startup and less than than a year old
    if (account.is_startup && oneYearLater > toDayDateTime) {
      for (let index = 0; index < settingsLength; index++) {
        const setting = settings[index];
        console.log(startup_setup_fee, setting.s_key);

        if (setting.s_key === startup_setup_fee) {
          this.setState({
            amount: parseFloat(setting.value),
            type: SETUP_FEE,
            paymentDesc: "Account Setup Subscription Fee"
          });
        }
      }
    } else {
      for (let index = 0; index < settingsLength; index++) {
        const setting = settings[index];
        console.log(regular_setup_fee, setting.s_key);

        if (setting.s_key === regular_setup_fee) {
          this.setState({
            amount: parseFloat(setting.value),
            type: SETUP_FEE,
            paymentDesc: "Account Setup Subscription Fee"
          });
        }
      }
    }

    console.log("this.state.amount", this.state.amount);
  }

  //   Process Point Calculation
  processPoints(point) {
    let account = this.props.account;

    console.log({ account });

    var minPoint = 1000;

    console.log({ point });
    let plan = account.search_plan;

    if (plan && point) {
      if (account.is_startup) {
        minPoint = plan.startup_min_point;
      } else {
        minPoint = plan.min_point;
      }
      this.setState({
        points: point,
        minPoint: minPoint
      });

      let rates = plan.rates;

      let length = rates.length;

      for (let index = 0; index < length; index++) {
        const rate = rates[index];
        // Check if account is startup
        // Change point to Min Startup Point
        if (point < plan.startup_min_point && account.is_startup) {
          minPoint = plan.startup_min_point;
        }

        // Change point to Min Startup Point if account is not startup
        // and point is lesser than minimum
        if (point < plan.min_point && !account.is_startup) {
          minPoint = plan.min_point;
        }

        if (point >= rate.min_point && point <= rate.max_point) {
          let amount = point * rate.cost_per_point;

          this.setState({
            minPoint: minPoint,
            points: point,
            amount: amount,
            type: POINT_PURCHASE,
            paymentDesc: "Search Query Point Purchase"
          });
        }
      }
    }
  }

  render() {
    const { type } = this.props.match.params;

    let title = type === setup_fee ? "Annual Setup Fee" : "PEX Points Purchase";

    return (
      <PXLayout>
        <div>
          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <div>
                <h5 className="page-title">{title}</h5>
                <hr />
              </div>
              <div className="form-content shadow p-3 mb-5 bg-white rounded">
                {type === point_purchase && !this.state.isPageLoading ? (
                  <ValidationForm
                    onSubmit={e => e.preventDefault()}
                    onErrorSubmit={this.handleErrorSubmit}
                    immediate={true}
                    setFocusOnError={true}
                    defaultErrorMessage={{ required: "Enter a valid value." }}
                  >
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        controlId="point"
                        className="form-group"
                      >
                        <TextInput
                          id="point"
                          className="form-control"
                          name="point"
                          type="text"
                          defaultValue={this.state.points}
                          onBlur={e => this.handleChange(e)}
                          onChange={e => {
                            this.handleChange(e);
                            e.target.setAttribute("value", e.target.value);
                          }}
                          validator={val => {
                            var isInt = validator.isInt(val, {
                              min: this.state.minPoint
                            });
                            // console.log({ isInt });
                            // console.log(this.state.points);
                            isInt
                              ? this.setState({ isFormError: false })
                              : this.setState({ isFormError: true });
                            return isInt;
                          }}
                          errorMessage={{
                            validator: `Please enter a Valid Number. Min: ${this.state.minPoint}`
                          }}
                          required
                        />
                        <Form.Label className="form-control-placeholder">
                          Points
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>
                  </ValidationForm>
                ) : (
                  <></>
                )}

                {!this.props.isLoading && !this.state.isPageLoading ? (
                  <div>
                    {this.state.amount &&
                    this.state.minPoint <= this.state.points ? (
                      <div className="text-center">
                        <h4 className="page-title">${this.state.amount}</h4>
                        <hr />
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                    {(this.state.minPoint > this.state.points &&
                      type === point_purchase) ||
                    this.state.isFormError ? (
                      <></>
                    ) : (
                      <PayPalButton
                        createOrder={async (data, actions) => {
                          console.log(this.state.amount.toString());
                          return await this.init(this.state.points).then(() => {
                            return actions.order.create({
                              purchase_units: [
                                {
                                  description: this.state.paymentDesc,
                                  amount: {
                                    currency_code: "USD",
                                    value: this.state.amount.toString()
                                  }
                                }
                              ],
                              application_context: {
                                shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                              }
                            });
                          });
                        }}
                        shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        onSuccess={(details, data) => {
                          console.log("PAYPAL RESPONSE data", { data });
                          console.log("PAYPAL RESPONSE details", { details });
                          // alert("Transaction completed by " + details.payer.name.given_name);

                          let amountPaid = parseFloat(
                            details.purchase_units[0].amount.value
                          );

                          this.verifyPaymentFromServer(
                            data.orderID,
                            amountPaid
                          ).then(() => {
                            this.setState({
                              amount: 0.0
                            });
                            // Send user to point purchase after activating account
                            if (this.state.type === SETUP_FEE) {
                              this.props.history.push(
                                `/billing/${point_purchase}`
                              );
                              window.location.reload();
                            } else {
                              this.props.history.push("/dashboard");
                            }
                          });
                        }}
                        onError={error => console.log({ error })}
                        options={{
                          commit: true,
                          clientId: this.getClientId()
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div className="text-center mt-3">
                  <Row>
                    {!this.props.isLoading && !this.props.isSuccess ? (
                      <div className="error text-center">
                        <strong>{this.props.message}</strong>
                      </div>
                    ) : (
                      <div className="success text-center">
                        <strong>{this.props.message}</strong>
                      </div>
                    )}
                  </Row>
                  <br />
                  {this.props.isLoading || this.state.isPageLoading ? (
                    <Spinner animation="grow" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </PXLayout>
    );
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    console.log(e, formData, errorInputs);
  };

  handleChange(event) {
    switch (event.target.id) {
      case "point":
        this.processPoints(Number(event.target.value));
        break;
      default:
        break;
    }
  }
}

BillingPage.propTypes = {
  settings: PropTypes.array,
  account: PropTypes.object,
  payment: PropTypes.object,
  match: PropTypes.any,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  history: PropTypes.any,
  message: PropTypes.string,
  verifyPayment: PropTypes.func,
  resetStatus: PropTypes.func,
  getSettingsAction: PropTypes.func,
  initAuthStore: PropTypes.func,
  dispatch: PropTypes.any
};

const mapStateToProps = state => {
  const settings = getSettings(state);
  const account = getSelectedAccount(state);
  const payment = getPayment(state);
  const isSuccess = getSuccessStatus(state);
  const message = getMessage(state);
  const isLoading = getLoadingStatus(state);
  console.log({ account });
  return {
    settings,
    account,
    payment,
    isLoading,
    message,
    isSuccess
  };
};

const mapDispatchActionToProps = dispatch => {
  return {
    verifyPayment,
    resetStatus,
    getSettingsAction,
    initAuthStore,
    dispatch
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchActionToProps)(BillingPage)
);
