// Get customer state from the store
export const getCustomerState = store => store.customer;

// CUSTOMER SELECTOR

// Get Customer Customers Data State from store
export const getCustomersState = store =>
  getCustomerState(store) ? getCustomerState(store).customers : {};

// Get Customer Customers Data List from store
export const getCustomerList = store =>
  getCustomersState(store) ? getCustomersState(store).iDs : [];

// Get Customer Customers Data By ID from store
export const getCustomersByID = (store, id) =>
  getCustomersState(store) ? { ...getCustomersState(store).byIDs[id], id } : {};

// Get all customers
export const getCustomers = store =>
  getCustomerList(store).map(id => getCustomersByID(store, id));

// Get Selected customer
export const getCustomer = (store, id) => getCustomersByID(store, id);

// Get Message
export const getMessage = store =>
  getCustomerState(store) ? getCustomerState(store).message : "";
// Get Loading status
export const getLoadingStatus = store =>
  getCustomerState(store) ? getCustomerState(store).isLoading : false;

// Get Success status
export const getSuccessStatus = store =>
  getCustomerState(store) ? getCustomerState(store).isSuccess : false;

// Get Next Page
export const getNextPage = store =>
  getCustomerState(store) ? getCustomerState(store).nextPage : 0;

// Get Current Page
export const getCurrentPage = store =>
  getCustomerState(store) ? getCustomerState(store).currentPage : 1;
