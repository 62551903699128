import React from "react";
import PropTypes from "prop-types";
import styles from "./css/InfoCard.module.css";

class PXInfoCard extends React.Component {
  //   constructor(props) {
  //     super(props);
  //   }

  render() {
    return (
      <div className="text-center">
        <div className={styles.card}>
          {this.props.icon}
          <p>{this.props.text}</p>
          <h2>{this.props.value}</h2>
        </div>
      </div>
    );
  }
}

PXInfoCard.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  value: PropTypes.number
};

export default PXInfoCard;
