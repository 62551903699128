import { reactLocalStorage } from "reactjs-localstorage";
import { LOCAL_STORAGE_KEYS } from "../../keys";
import Cookies from "js-cookie";

const {
  token,
  role,
  customer,
  admin,
  dashboard,
  accounts,
  accountID
} = LOCAL_STORAGE_KEYS;

// Check Authentication
export const checkAuth = () => {
  let savedToken = Cookies.get(token);

  // console.log("savedToken", {savedToken});

  return savedToken;
};

// Check Authentication
export const checkRole = () => {
  let savedRole = Cookies.get(role);

  return savedRole;
};

/**
 * Get the local saved customer/Admin data and process it
 */
export const processAuthData = async () => {
  const customerData = await reactLocalStorage.getObject(customer, {}, true);
  const adminData = await reactLocalStorage.getObject(admin, {}, true);
  const dashboardData = await reactLocalStorage.getObject(dashboard, {}, true);
  const accountsData = await reactLocalStorage.getObject(accounts, {}, true);
  const accountId = await reactLocalStorage.get(accountID, true);
  const isAdmin = checkRole() === admin;

  let byIDs = {};

  let iDs = isAdmin
    ? []
    : accountsData.map(account => {
        byIDs[account.id] = account;
        return account.id;
      });
  var selectedID = null;

  if (!isAdmin) {
    selectedID = accountId ? accountId : accountsData[0].id;
  }

  // console.log({selectedID});

  const accountState = {
    iDs: iDs,
    selectedID: selectedID,
    byIDs: byIDs
  };

  const adminState = {
    profile: adminData,
    dashboardData: dashboardData
  };

  console.log("authState", {
    isAdmin: isAdmin,
    admin: adminState,
    customer: customerData,
    accounts: accountState
  });

  return {
    isAdmin: isAdmin,
    admin: adminState,
    customer: customerData,
    accounts: accountState
  };
};

/**
 * Process countries data and return the object structure of the store
 * @param countriesData Array -
 * @returns object { iDs: [], byIDs: {} }
 */
export const processCountriesData = countriesData => {
  let byIDs = {};
  let iDs = countriesData.map(country => {
    byIDs[country.iso] = country;
    return country.iso;
  });

  const countriesState = {
    iDs: iDs,
    byIDs: byIDs
  };

  return countriesState;
};

/**
 * Process settings data and return the object structure of the store
 * @param data Array -
 * @returns object { keys: [], byKeys: {}}
 */
export const processSettingsData = data => {
  let byKeys = {};

  let keys = data.map(setting => {
    byKeys[setting.s_key] = setting;
    return setting.s_key;
  });

  const settingsState = {
    keys: keys,
    byKeys: byKeys
  };

  return settingsState;
};

/**
 * Process Data and return the object structure of the store
 * @param data Array -
 * @returns object { iDs: [], byIDs: {} }
 */
export const processData = data => {
  let byIDs = {};
  let iDs = data.map(item => {
    byIDs[item.id] = item;
    return item.id;
  });

  const dataState = {
    iDs: iDs,
    byIDs: byIDs
  };

  return dataState;
};

/**
 * Process Error Message Data returned from the server
 * @param err Object -
 * @returns string
 */
export const processErrorMessage = err => {
  var message = "";
  if (err.response) {
    if (err.response.data.message) {
      message = err.response.data.message;
    }

    if (err.response.data.error) {
      var error = err.response.data.error;
      console.log({ error });
      if (error.phone) {
        message = `${message} \n ${error.phone}`;
      }
      if (error.name) {
        message = `${message} \n ${error.name}`;
      }
      if (error.search_plan_id) {
        message = `${message} \n ${error.search_plan_id}`;
      }
      if (error.startup_token) {
        message = `${message} \n ${error.startup_token}`;
      }
      if (error.type) {
        message = `${message} \n ${error.type}`;
      }
      if (error.email) {
        message = `${message} \n ${error.email}`;
      }
      if (error.username) {
        message = `${message} \n ${error.username}`;
      }
      if (error.first_name) {
        message = `${message} \n ${error.first_name}`;
      }
      if (error.last_name) {
        message = `${message} \n ${error.last_name}`;
      }
      if (error.country_iso) {
        message = `${message} \n ${error.country_iso}`;
      }
      if (error.password) {
        message = `${message} \n ${error.password}`;
      }
      if (error.paypal_order_id) {
        message = `${message} \n ${error.paypal_order_id}`;
      }
      if (error.amount_paid) {
        message = `${message} \n ${error.amount_paid}`;
      }
      if (error.account_id) {
        message = `${message} \n ${error.account_id}`;
      }
      if (error.points) {
        message = `${message} \n ${error.points}`;
      }
      if (error.min_point) {
        message = `${message} \n ${error.min_point}`;
      }
      if (error.startup_min_point) {
        message = `${message} \n ${error.startup_min_point}`;
      }
      if (error.include_analytics) {
        message = `${message} \n ${error.include_analytics}`;
      }
      if (error.show_miles_to_cash) {
        message = `${message} \n ${error.show_miles_to_cash}`;
      }
      if (error.allow_price_alert) {
        message = `${message} \n ${error.allow_price_alert}`;
      }
      if (error.search_plan_id) {
        message = `${message} \n ${error.search_plan_id}`;
      }
      if (error.max_point) {
        message = `${message} \n ${error.max_point}`;
      }
      if (error.cost_per_point) {
        message = `${message} \n ${error.cost_per_point}`;
      }
    }
  }

  return message;
};
