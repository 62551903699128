import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  getAccounts,
  getSelectedAccount
} from "../modules/selectors/auth.selectors";
import Dropdown from "react-bootstrap/Dropdown";
import { reactLocalStorage } from "reactjs-localstorage";
import styles from "./css/SideBar.module.css";
import { LOCAL_STORAGE_KEYS } from "../keys";
import { Button } from "react-bootstrap";

class PXSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localData: {}
    };
  }

  componentDidMount() {}

  // Changje currently selected account
  changeSelectedAccount(accountId) {
    const { accountID } = LOCAL_STORAGE_KEYS;
    reactLocalStorage.set(accountID, accountId);
    window.location.reload();
  }

  render() {
    const accountsDropdownItems = this.props.accounts.map(account => (
      <Dropdown.Item
        to="#"
        key={account.id}
        onClick={() => this.changeSelectedAccount(account.id)}
      >
        {account.name}
      </Dropdown.Item>
    ));

    return (
      <>
        <div className={styles.sideBarBody}>
          {this.props.accounts.length < 1 ? (
            <div></div>
          ) : (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-warning"
                  id="dropdown-basic"
                  className={styles.dropdownToggle}
                >
                  {this.props.account.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {accountsDropdownItems}
                  <hr />
                  <Link to="/create-account">
                    <Button key="create_account" className={styles.button}>
                      Create Account
                    </Button>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              <Link to="/dashboard">
                <div className={styles.card}>
                  <p>Dashboard</p>
                </div>
              </Link>

              <Link to={`/search-history/${this.props.account.id}`}>
                <div className={styles.card}>
                  <p>Search History</p>
                </div>
              </Link>

              <Link to="/payment">
                <div className={styles.card}>
                  <p>Payments</p>
                </div>
              </Link>

              <Link to="/account">
                <div className={styles.card}>
                  <p>Account</p>
                </div>
              </Link>
            </>
          )}
        </div>
      </>
    );
  }
}

PXSideBar.propTypes = {
  account: PropTypes.object,
  accounts: PropTypes.array
};

// Map the need state properties to the component property
const mapStateToProps = state => {
  const accounts = getAccounts(state);
  const account = getSelectedAccount(state);
  // console.log(account, accounts)
  return { accounts, account };
};

export default connect(mapStateToProps)(PXSideBar);
