import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./css/SideBar.module.css";

class PXAdminSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localData: {}
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className={styles.sideBarBody}>
          <>
            <Link to="/admin/dashboard">
              <div className={styles.card}>
                <p>Dashboard</p>
              </div>
            </Link>

            <Link to="/admin/customers">
              <div className={styles.card}>
                <p>Customers</p>
              </div>
            </Link>

            <Link to="/admin/accounts">
              <div className={styles.card}>
                <p>API Accounts</p>
              </div>
            </Link>

            <Link to="/admin/payments">
              <div className={styles.card}>
                <p>Payments</p>
              </div>
            </Link>

            <Link to="/admin/search-plans">
              <div className={styles.card}>
                <p>Search API Plans</p>
              </div>
            </Link>

            <Link to="/admin/tokens">
              <div className={styles.card}>
                <p>Startup Tokens</p>
              </div>
            </Link>

            <Link to="/admin/settings">
              <div className={styles.card}>
                <p>Settings</p>
              </div>
            </Link>

            <Link to="/admin/logs">
              <div className={styles.card}>
                <p>Activity Logs</p>
              </div>
            </Link>
          </>
        </div>
      </>
    );
  }
}

PXAdminSideBar.propTypes = {
  account: PropTypes.object,
  accounts: PropTypes.array
};

export default connect()(PXAdminSideBar);
