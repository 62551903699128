import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAdminProfileDashboardData } from "../../modules/actions/auth.actions";
import {
  getAdminProfile,
  getAdminDashboardData
} from "../../modules/selectors/auth.selectors";
import PXLayout from "../../components/Layout";
import PXInfoCard from "../../components/InfoCard";
import PropTypes from "prop-types";

class AdminDashboardPage extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // console.log(Router.query.authtoken);

  //   this.handleChange = this.handleChange.bind(this);
  //   // this.handleSubmit = this.handleSubmit.bind(this);
  // }

  componentDidMount() {
    getAdminProfileDashboardData();
    // console.log("Localdata", localData);
  }

  render() {
    let admin = `${this.props.admin.first_name}`;

    return (
      <PXLayout>
        <div>
          <div>
            <h4 className="page-title">Welcome, {admin}</h4>
            <hr />
          </div>
          <br />
          <br />
          <div>
            <Row>
              <Col md={4} sm={6}>
                <PXInfoCard
                  value={this.props.dashboardData.customers_count}
                  text="Total Customers"
                  icon={<i className="fas fa-users fa-3x"></i>}
                />
              </Col>
              <Col md={4} sm={6}>
                <PXInfoCard
                  value={this.props.dashboardData.active_customers_count}
                  text="Active Customers"
                  icon={<i className="fas fa-users fa-3x"></i>}
                />
              </Col>
              <Col md={4} sm={6}>
                <PXInfoCard
                  value={this.props.dashboardData.pending_customers_count}
                  text="Pending Customers"
                  icon={<i className="fas fa-users fa-3x"></i>}
                />
              </Col>
              <Col md={4} sm={6}>
                <PXInfoCard
                  value={this.props.dashboardData.accounts_count}
                  text="Total API Accounts"
                  icon={<i className="fas fa-address-card fa-3x"></i>}
                />
              </Col>
              <Col md={4} sm={6}>
                <PXInfoCard
                  value={this.props.dashboardData.active_accounts_count}
                  text="Active API Accounts"
                  icon={<i className="fas fa-address-card fa-3x"></i>}
                />
              </Col>
              <Col md={4} sm={6}>
                <PXInfoCard
                  value={this.props.dashboardData.pending_accounts_count}
                  text="Pending API Accounts"
                  icon={<i className="fas fa-address-card fa-3x"></i>}
                />
              </Col>
            </Row>
          </div>
        </div>
      </PXLayout>
    );
  }
}

AdminDashboardPage.propTypes = {
  admin: PropTypes.object,
  dashboardData: PropTypes.object,
  updatePlansStore: PropTypes.func,
  updateCountriesStore: PropTypes.func
};

const mapStateToProps = state => {
  const admin = getAdminProfile(state);
  const dashboardData = getAdminDashboardData(state);
  // console.log(admin, dashboardData);
  return { admin, dashboardData };
};

export default connect(mapStateToProps)(AdminDashboardPage);
