import React from "react";
import { connect } from "react-redux";
import { getCountries } from "../modules/selectors/global.selectors";
import { updateCountriesStore } from "../modules/actions/global.actions";
import { getRequest } from "../modules/utils/service";
import { processCountriesData } from "../modules/utils/helpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PXAuthForm from "../components/AuthForm";
import PropTypes from "prop-types";
import { LOCAL_STORAGE_KEYS } from "../keys";

const { admin } = LOCAL_STORAGE_KEYS;

class LoginPage extends React.Component {
  // constructor(props) {
  //   super(props);

  // }

  componentDidMount() {
    if (this.props.countries.length < 1) {
      getRequest("countries", false)
        .then(res => {
          let countries = processCountriesData(res.data.countries);

          this.props.updateCountriesStore(countries);
        })
        .catch(err => {
          console.log("An Error Occurred while fetching Countries", { err });
        });
    }
  }

  render() {
    const { role } = this.props.match.params;

    let isAdmin = role === admin;

    return (
      <div className="body">
        <div className="content">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <div className="form-content shadow pt-3 pb-3 mb-5 bg-white rounded">
                <PXAuthForm isAdmin={isAdmin} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  countries: PropTypes.array,
  match: PropTypes.any,
  updateCountriesStore: PropTypes.func
};

const mapStateToProps = state => {
  const countries = getCountries(state);

  return { countries };
};

export default connect(mapStateToProps, { updateCountriesStore })(LoginPage);
