import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PXLayout from "../components/Layout";
import PXAccountCard from "../components/AccountCard";
import PropTypes from "prop-types";
import { getSelectedAccount } from "../modules/selectors/auth.selectors";

class AccountPage extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // console.log(Router.query.authtoken);

  //   this.handleChange = this.handleChange.bind(this);
  //   // this.handleSubmit = this.handleSubmit.bind(this);
  // }

  componentDidMount() {
    // console.log("Localdata", localData);
  }

  render() {
    console.log("account", this.props.account);

    return (
      <PXLayout>
        <div>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              {this.props.account.id ? (
                <PXAccountCard account={this.props.account} />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </PXLayout>
    );
  }
}

AccountPage.propTypes = {
  account: PropTypes.object
};

// Map the need state properties to the component property
const mapStateToProps = state => {
  const account = getSelectedAccount(state);
  console.log(account);
  return { account };
};

export default connect(mapStateToProps)(AccountPage);
