import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getSearchesAction } from "../modules/actions/searche.actions";
import { getSelectedAccount } from "../modules/selectors/auth.selectors";
import {
  getSearches,
  getNextPage,
  getCurrentPage,
  getSuccessStatus,
  getMessage,
  getLoadingStatus
} from "../modules/selectors/searche.selectors";
import Pagination from "react-bootstrap/Pagination";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import PXLayout from "../components/Layout";

class SearchPage extends React.Component {
  //   constructor(props) {nextPage

  componentDidMount() {
    this.getSearchesFromServer(this.props.currentPage);
  }

  getSearchesFromServer(page) {
    const { accountID } = this.props.match.params;

    console.log("this.props.account.id", accountID);
    console.log("page", page);
    this.props.getSearchesAction(this.props.dispatch, accountID, page);
  }

  render() {
    let pgItems = [];
    if (this.props.currentPage > 1 && !this.props.isLoading) {
      pgItems.push(
        <Pagination.Prev
          onClick={() => this.getSearchesFromServer(this.props.currentPage)}
        />
      );
    }

    if (this.props.nextPage > 1 && !this.state.isSearched) {
      pgItems.push(
        <Pagination.Next
          onClick={() => this.getSearchesFromServer(this.props.nextPage)}
        />
      );
    }

    const serialNumber = this.props.currentPage * 20 - 20 + 1;

    const rows = this.props.searches.map((searche, index) => {
      return (
        <tr key={searche.id.toString()}>
          <td className="td-border-left">{serialNumber + index}</td>
          <td>{searche.keyword}</td>
          <td>{searche.point_cost}</td>
          <td className="td-border-right">
            {new Date(searche.created_at).toLocaleString()}
          </td>
        </tr>
      );
    });

    return (
      <PXLayout>
        <div>
          <div>
            <h5 className="page-title">Keyword Search History</h5>

            <hr />
          </div>
          <div className="justify-content-center">
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>KEYWORD</th>
                  <th>POINT COST</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            <Row>
              <Col md={{ span: 4, offset: 5 }}>
                {this.props.isLoading ? (
                  <Spinner animation="grow" />
                ) : (
                  <Pagination>{pgItems}</Pagination>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </PXLayout>
    );
  }
}

SearchPage.propTypes = {
  account: PropTypes.object,
  searches: PropTypes.array,
  currentPage: PropTypes.number,
  nextPage: PropTypes.number,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  getSearchesAction: PropTypes.func,
  dispatch: PropTypes.any,
  match: PropTypes.any
};

const mapStateToProps = state => {
  const account = getSelectedAccount(state);
  const searches = getSearches(state);
  const nextPage = getNextPage(state);
  const currentPage = getCurrentPage(state);
  const isSuccess = getSuccessStatus(state);
  const message = getMessage(state);
  const isLoading = getLoadingStatus(state);
  return {
    account,
    searches,
    isLoading,
    message,
    isSuccess,
    currentPage,
    nextPage
  };
};

const mapDispatchActionToProps = dispatch => {
  return {
    getSearchesAction,
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchActionToProps)(SearchPage);
