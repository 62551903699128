export const LOCAL_STORAGE_KEYS = {
  token: "token",
  role: "role",
  admin: "admin",
  dashboard: "dashboard",
  customer: "customer",
  accounts: "accounts",
  accountID: "accountID",
  setup_fee: "setup_fee",
  point_purchase: "point_purchase",
  regular_setup_fee: "regular_setup_fee",
  startup_setup_fee: "startup_setup_fee"
};
