import React from "react";
import { connect } from "react-redux";
import { getCountries } from "../modules/selectors/global.selectors";
import { getPlans } from "../modules/selectors/plan.selectors";
import { getCountriesAction } from "../modules/actions/global.actions";

import { getPlansAction } from "../modules/actions/plan.actions";

import PXLayout from "../components/Layout";
import PXAccountForm from "../components/AccountForm";
import PropTypes from "prop-types";

class CreateAccountPage extends React.Component {
  // constructor(props) {
  //   super(props);

  // }

  componentDidMount() {
    if (this.props.countries.length < 1) {
      this.props.getCountriesAction(this.props.dispatch);
    }
    if (this.props.plans.length < 1) {
      this.props.getPlansAction(this.props.dispatch);
    }
  }

  render() {
    return (
      <PXLayout>
        <div>
          <PXAccountForm />
        </div>
      </PXLayout>
    );
  }
}

CreateAccountPage.propTypes = {
  countries: PropTypes.array,
  plans: PropTypes.array,
  getPlansAction: PropTypes.func,
  getCountriesAction: PropTypes.func,
  dispatch: PropTypes.any
};

const mapStateToProps = state => {
  const countries = getCountries(state);
  const plans = getPlans(state);

  return { countries, plans };
};

const mapDispatchActionToProps = dispatch => {
  return {
    getCountriesAction,
    getPlansAction,
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchActionToProps
)(CreateAccountPage);
