import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  getCustomerState,
  getSelectedAccount
} from "../modules/selectors/auth.selectors";
import PXLayout from "../components/Layout";
import PXBanner from "../components/Banner";
import PXInfoCard from "../components/InfoCard";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { LOCAL_STORAGE_KEYS } from "../keys";

class DashboardPage extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // console.log(Router.query.authtoken);

  //   this.handleChange = this.handleChange.bind(this);
  //   // this.handleSubmit = this.handleSubmit.bind(this);
  // }

  componentDidMount() {
    // console.log("Localdata", localData);
  }

  render() {
    const { setup_fee, point_purchase } = LOCAL_STORAGE_KEYS;

    const rows = this.props.account.recent_searches ? (
      this.props.account.recent_searches.map(search => (
        <tr key={search.id.toString()}>
          <td className="td-border-left">{search.keyword}</td>
          <td>{search.point_cost}</td>
          <td className="td-border-right">
            {new Date(search.created_at).toLocaleString()}
          </td>
        </tr>
      ))
    ) : (
      <></>
    );

    let customer = `${this.props.customer.first_name}`;

    return (
      <PXLayout>
        <div>
          <div>
            <h4 className="page-title">Welcome, {customer}</h4>
            <hr />
          </div>
          <br />
          <br />
          {!this.props.account.id ? (
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <PXBanner />
              </Col>
            </Row>
          ) : (
            <div>
              <Row>
                <Col md={4} sm={6}>
                  <PXInfoCard
                    value={this.props.account.point_balance}
                    text="PEX Point Balance"
                    icon={<i className="far fa-circle fa-3x"></i>}
                  />
                </Col>
                <Col md={4} sm={6}>
                  <PXInfoCard
                    value={this.props.account.point_used}
                    text="PEX Points Used"
                    icon={<i className="fas fa-circle fa-3x"></i>}
                  />
                </Col>
                <Col md={4} sm={6}>
                  <PXInfoCard
                    value={this.props.account.total_search}
                    text="PEX Total Search"
                    icon={<i className="fas fa-search fa-3x"></i>}
                  />
                </Col>
              </Row>
              <div className="text-center mt-3">
                <br />
                {this.props.account.is_approved ? (
                  <Link to={`/billing/${point_purchase}`}>
                    <Button variant="primary">BUY POINTS</Button>
                  </Link>
                ) : (
                  <Link to={`/billing/${setup_fee}`}>
                    <Button variant="primary">ACTIVATE ACCOUNT</Button>
                  </Link>
                )}
              </div>
              <Row>
                <Col md={{ span: 8, offset: 0 }}>
                  {this.props.account.recent_searches < 1 ? (
                    <></>
                  ) : (
                    <>
                      <h5 className="page-title">RECENT SEARCH</h5>

                      <Table bordered hover responsive>
                        <thead>
                          <tr>
                            <th>KEYWORD</th>
                            <th>POINT COST</th>
                            <th>DATE</th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </Table>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </div>
      </PXLayout>
    );
  }
}

DashboardPage.propTypes = {
  customer: PropTypes.object,
  account: PropTypes.object,
  updatePlansStore: PropTypes.func,
  updateCountriesStore: PropTypes.func
};

const mapStateToProps = state => {
  const customer = getCustomerState(state);
  const account = getSelectedAccount(state);
  // console.log(customer, account);
  return { customer, account };
};

export default connect(mapStateToProps)(DashboardPage);
