// Get auth state from the store
export const getAuthState = store => store.auth;
// Get Auth Data State from store
export const getAuthDataState = store =>
  getAuthState(store) ? getAuthState(store).data : {};

// Get Auth Customer Data State from store
export const getCustomerState = store =>
  getAuthDataState(store) ? getAuthDataState(store).customer : {};

// Get Auth Admin Data State from store
export const getAdminState = store =>
  getAuthDataState(store) ? getAuthDataState(store).admin : {};

// Get Auth Customer Accounts Data State from store
export const getAccountsState = store =>
  getAuthDataState(store) ? getAuthDataState(store).accounts : {};
// Get Auth Customer Accounnt Data List from store
export const getAccountList = store =>
  getAccountsState(store) ? getAccountsState(store).iDs : [];
// Get Auth Customer Accounnt Data By ID from store
export const getAccountByID = (store, id) =>
  getAccountsState(store) ? { ...getAccountsState(store).byIDs[id] } : {};

// Get all Accounts
export const getAccounts = store =>
  getAccountList(store).map(id => getAccountByID(store, id));

// Get Selected Account
export const getSelectedAccount = store =>
  getAccountByID(store, getAccountsState(store).selectedID);

// Get admin profile
export const getAdminProfile = store =>
  getAdminState(store) ? getAdminState(store).profile : {};

// Get admin Dashboard Data
export const getAdminDashboardData = store =>
  getAdminState(store) ? getAdminState(store).dashboardData : {};

// Get admin status
export const getAdminStatus = store =>
  getAuthDataState(store) ? getAuthDataState(store).isAdmin : false;

// Get Message
export const getMessage = store =>
  getAuthState(store) ? getAuthState(store).message : "";
// Get Loading status
export const getLoadingStatus = store =>
  getAuthState(store) ? getAuthState(store).isLoading : false;

// Get Success status
export const getSuccessStatus = store =>
  getAuthState(store) ? getAuthState(store).isSuccess : false;
