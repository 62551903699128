import {
  UPDATE_CUSTOMERS,
  UPDATE_CUSTOMERS_STATUS
} from "../action-types/customer.actionTypes";
import { getRequest } from "../utils/service";
import { processData } from "../utils/helpers";

// Process Customer Update action
export const getCustomers = (dispatch, page = 1) => {
  dispatch({
    type: UPDATE_CUSTOMERS_STATUS,
    payload: {
      isSuccess: false,
      isLoading: true,
      message: "Fetching Customers..."
    }
  });

  let url = `admin/customers?page=${page}&limit=20`;

  getRequest(url, true)
    .then(res => {
      let customers = processData(res.data.customers);

      let nextPage = res.data.next_page;
      let currentPage = res.data.current_page;

      dispatch({
        type: UPDATE_CUSTOMERS,
        payload: {
          isSuccess: true,
          isLoading: false,
          message: "",
          nextPage: nextPage,
          currentPage: currentPage,
          data: customers
        }
      });
    })
    .catch(err => {
      dispatch({
        type: UPDATE_CUSTOMERS_STATUS,
        payload: {
          isSuccess: false,
          isLoading: false,
          message: "Error Fetching Customers..."
        }
      });
      console.log("An Error Occurred while fetching App Settings", { err });
    });
};
